import React, {Component} from "react";

// reactstrap components
import {
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col, Badge
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import GeneralHeader from "../components/Headers/GeneralHeader";
import ApiService from "../services/classes/Api";
import {TextField, Button, Menu, MenuItem, InputLabel, Select, Tooltip, IconButton} from "@material-ui/core";
import Validator from "../helpers/Validations";
import Loading from "../components/General/Loading";
import {Slide, toast} from "react-toastify";
import moment from "moment";
import ReactApexChart from 'react-apexcharts'
import RecordDataForm from "../components/General/RecordDataForm";
import {CloudDownload, EmojiEmotions, TextFields} from '@material-ui/icons'

class  Report extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			record: {},
			topologies: [],
			validationDropdown: false,
			report: {}
		}
	}

	componentDidMount() {
		this.loadData();
	}

	loadData() {
		const { match: { params: { oid } } } = this.props;
		ApiService.graph(`
			{
				record(_id: "${oid}") {
					_id
     			type
     			state
     			meta
     			data
     			versions
     			createdAt
     			updatedAt
				}
				
				topologies {
					_id
					name
				}
			}
		`).then(response => {
			if(response.status === 200) {
				this.setState({
					record: response.data.data.record,
					topologies: response.data.data.topologies
				});
				ApiService.graph(`
					{
						topology(name: "${this.state.record.type}") {
							_id
							name
							shape
							states
						}
					}
				`).then(response => {
					if(response.status === 200) {
						ApiService.getReport(this.state.record.data.token).then(reportRequest => {
							if(response.status === 200) {
								this.setState({
									topology: response.data.data.topology,
									report: reportRequest.data.report
								});
							}
						}).catch(e => console.log(e));
					}
				}).catch(e => console.log(e));
			}
		}).catch(e => console.log(e));
	}

	setData(data) {
		let { record } = this.state;
		record.data = data;
		this.setState({ record });
	}

	isDataValid() {
		const { state } = this.props;
		const { record, topology } = this.state;
		const result = Validator.validate(record.data, topology, record.state);
		return result.length <= 0;
	}

	saveData() {
		let { record } = this.state;
		if(this.isDataValid()) {
			ApiService.editRecord(record.type, record._id, record.data, record.state).then(response => {
				if(response.status === 200 && response.data.response === "success") {
					toast(`Record modificato correttamente.`, {
						transition: Slide,
						closeButton: true,
						autoClose: 2000,
						position: 'bottom-center',
						type: 'success'
					});
					this.loadData();
				} else {
					toast("Si è verificato un problema, riprova più tardi.", {
						transition: Slide,
						closeButton: true,
						autoClose: 2000,
						position: 'bottom-center',
						type: 'error'
					})
				}
			})
		} else {
			toast("Alcuni campi del form risultano invalidi.", {
				transition: Slide,
				closeButton: true,
				autoClose: 2000,
				position: 'bottom-center',
				type: 'error'
			})
		}
	}

	changeState(state) {
		let { record } = this.state;
		ApiService.setRecordState(record._id, state).then(response => {
			if(response.status === 200 && response.data.response === "success") {
				toast(`Record modificato correttamente.`, {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'success'
				});
				this.loadData();
			} else {
				toast("Si è verificato un problema, riprova più tardi.", {
					transition: Slide,
					closeButton: true,
					autoClose: 2000,
					position: 'bottom-center',
					type: 'error'
				})
			}
		})
	}

	export() {
		this.setState({loading: true});
		ApiService.exportSurvey(this.state.record.data.token).then(response => response.blob()).then(blob => {

			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `export_${moment(new Date()).format("YYYY-MM-DD_HH-mm")}.xlsx`);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);

			this.setState({loading: false});
		})
	}

	render() {

		if(!this.state.record || !this.state.topology || !this.state.report) {
			return <Loading />
		}

		const { record: { data, meta, author, createdAt, state, versions }, topology, topologies, report } = this.state;
		const tagToString = {
			"pens_critico": "Pensiero critico",
			"ment_indiz": "Mentalità indiziaria",
			"emo_intel": "Intelligenza emotiva"
		}

		return (
			<>
				<GeneralHeader
					title={`${data.name} ${data.surname}`}
					description={`Compilato il ${moment(parseInt(createdAt)).format("DD/MM/YYYY, HH:mm:ss")}`}
					actions={
						<Tooltip title={"Esporta risposte"}>
							<IconButton onClick={this.export.bind(this)} style={{color: "#fff"}}>
								<CloudDownload />
							</IconButton>
						</Tooltip>
					}
				/>
				<Container className="mt--7" style={{maxWidth: "1400px"}}>

					<Row className={""} style={{display: "flex", alignItems: "stretch"}}>
						<Col md={6} className={"mt-4"}>
							<Card className="bg-white shadow" style={{height: "100%"}}>
								<CardHeader>
									<h3 className={"mb-0"}>Quoziente di intelligenza digitale</h3>
								</CardHeader>
								<CardBody>
									<OverviewChart data={report} />
								</CardBody>
							</Card>
						</Col>
						<Col md={6} className={"mt-4"}>
							<Card className="bg-white shadow" style={{height: "100%"}}>
								<CardHeader>
									<h3 className={"mb-0"}>Capacità</h3>
								</CardHeader>
								<CardBody>
									<SectionOverviewIstogram data={report} />
								</CardBody>
							</Card>
						</Col>
					</Row>
					<Row className={"mt-4"}>
						{ report.sections.filter(s => s.maxScore > 0).map((section, i) => (
							<Col md={4} className={"mt-4"}>
								<Card className="bg-white shadow">
									<CardBody>
										<h3>{section.label}</h3>
										<Tooltip title={"Punteggio sezione"}>
											<SingleScoreChart value={section.weightedScore} />
										</Tooltip>
									</CardBody>
								</Card>
							</Col>
						)) }
						<Col md={4} className={"mt-4"}>
							<Card className="bg-white shadow">
								<CardHeader>
									<h3 className={"mb-0"}>Intelligenza emotiva</h3>
								</CardHeader>
								<CardBody>
									<SingleScoreChart value={report.overview.emot?.emo_intel || 0} />
								</CardBody>
							</Card>
						</Col>
						<Col md={4} className={"mt-4"}>
							<Card className="bg-white shadow">
								<CardHeader>
									<h3 className={"mb-0"}>Mentalità indiziaria</h3>
								</CardHeader>
								<CardBody>
									<SingleScoreChart value={report.overview.emot?.ment_indiz || 0} />
								</CardBody>
							</Card>
						</Col>
						<Col md={4} className={"mt-4"}>
							<Card className="bg-white shadow">
								<CardHeader>
									<h3 className={"mb-0"}>Pensiero critico</h3>
								</CardHeader>
								<CardBody>
									<SingleScoreChart value={report.overview.emot?.pens_critico || 0} />
								</CardBody>
							</Card>
						</Col>
					</Row>

					{ report.sections.filter(s => s.maxScore > 0).map((section, i) => (
						<Row className={"mt-4"} style={{alignItems: "flex-start"}}>
							<Col md={4} style={{position: "sticky", top: "16px"}}>
								<Card className="bg-white shadow">
									<CardBody>
										<h3>{section.label}</h3>
										<Tooltip title={"Punteggio sezione"}>
											<SingleScoreChart value={section.weightedScore} />
										</Tooltip>

										<Tooltip title={"Numero di domande risposte in questa sezione"}>
											<div style={{display: "flex", justifyContent: "space-between"}}>
												<span>Risposte date</span>
												<span><strong>{section.answers.length}</strong></span>
											</div>
										</Tooltip>
										<hr className={"my-3"}/>
										<Tooltip title={"Somma esatta dei punteggi delle singole risposte date per questa sezione"}>
											<div style={{display: "flex", justifyContent: "space-between"}}>
												<span>Punteggio effettivo</span>
												<span><strong>{section.score}</strong></span>
											</div>
										</Tooltip>
										<hr className={"my-3"}/>
										<Tooltip title={"Punteggio massimo totalizzabile in questa sezione"}>
											<div style={{display: "flex", justifyContent: "space-between"}}>
												<span>Punteggio massimo sezione</span>
												<span><strong>{section.maxScore}</strong></span>
											</div>
										</Tooltip>
									</CardBody>
								</Card>
							</Col>
							<Col md={8}>
								<Card className="bg-white shadow">
									<CardHeader>
										<h3 className={"mb-0"}>Domande della sezione</h3>
									</CardHeader>
									<CardBody>
										{ section.questions.map((question, q_i) => (
											<div className={`report-question my-3 ${data[question.id] ? "" : "no-answer"}`} style={{display: "flex", justifyContent: "space-between"}}>
												<div>
													<h3 style={{fontSize: "1.4em", fontWeight: 200}}>{question.title.it}</h3>
													<p style={{fontWeight: "bold"}}>{ data[question.id]?.values || " - "}</p>
												</div>
												{ question.type === "opentext" ? (
													<Tooltip title={"Domanda a risposta aperta senza punteggio"}>
														<div className={`noscore points`}><TextFields /></div>
													</Tooltip>
												) : (
													<Tooltip title={"Punteggio risposta"}>
														<div className={`${data[question.id]?.score > 1 ? (data[question.id]?.score > 3 ? "green" : "orange") : "red"} points`}>{Math.round(data[question.id]?.score) || 0}</div>
													</Tooltip>
												) }
											</div>
										)) }
									</CardBody>
								</Card>
							</Col>
						</Row>
					)) }



				</Container>
			</>
		);
	}
}

const OverviewChart = ({ data }) => {
	const series = [Math.round(data.overview.weightedScore)];
	const options = {
		chart: {
			width: "100%",
			type: 'radialBar',
		},
		plotOptions: {
			radialBar: {
				track: {
					background: data.overview.weightedScore > 40 ? (data.overview.weightedScore > 80 ? "#E7FAF0" : "#FFF6E6") : "#FFEEEC",
					stroke: 0
				},
				hollow: {
					size: '70%',
					margin: 0,
					background: data.overview.weightedScore > 40 ? (data.overview.weightedScore > 80 ? "#E7FAF0" : "#FFF6E6") : "#FFEEEC"
				},
				dataLabels: {
					name: {
						fontSize: '2.5em',
					},
					value: {
						fontSize: '5em',
						color: data.overview.weightedScore > 40 ? (data.overview.weightedScore > 80 ? "#0CCE6B" : "#FFA400") : "#FF4E42"
					},
					total: {
						show: true,
						label: '',
						formatter: function (w) {
							return Math.round(data.overview.weightedScore)
						}
					}
				}
			},
		},
		stroke: {
			lineCap: 'round'
		},
		labels: ['Punteggio Totale'],
		colors: data.overview.weightedScore > 40 ? (data.overview.weightedScore > 80 ? ["#0CCE6B"] : ["#FFA400"]) : ["#FF4E42"]
	};

	return (
		<div id="chart">
			<ReactApexChart options={options} series={series} type="radialBar" />
		</div>
	);
}

const SingleScoreChart = ({ value }) => {
	const series = [ Math.round(value) ];
	const options = {
		chart: {
			width: "100%",
			type: 'radialBar',
		},
		plotOptions: {
			radialBar: {
				track: {
					background: value > 40 ? (value > 80 ? "#E7FAF0" : "#FFF6E6") : "#FFEEEC"
				},
				hollow: {
					size: '70%',
					margin: 0,
					background: value > 40 ? (value > 80 ? "#E7FAF0" : "#FFF6E6") : "#FFEEEC"
				},
				dataLabels: {
					name: {
						fontSize: '2.5em',
					},
					value: {
						fontSize: '5em',
						color: value > 40 ? (value > 80 ? "#0CCE6B" : "#FFA400") : "#FF4E42"
					},
					total: {
						show: true,
						label: '',
						formatter: function (w) {
							return Math.round(value)
						}
					}
				}
			},
		},
		stroke: {
			lineCap: 'round'
		},
		labels: ['Punteggio Totale'],
		colors: value > 40 ? (value > 80 ? ["#0CCE6B"] : ["#FFA400"]) : ["#FF4E42"]
	};

	return (
		<div id="chart">
			<ReactApexChart options={options} series={series} type="radialBar" />
		</div>
	);
}

const MiniSingleScoreChart = ({ value }) => {
	const series = [ Math.round(value) ];
	const options = {
		chart: {
			width: "100%",
			type: 'radialBar',
		},
		plotOptions: {
			radialBar: {
				track: {
					background: value > 40 ? (value > 80 ? "#E7FAF0" : "#FFF6E6") : "#FFEEEC"
				},
				hollow: {
					size: '70%',
					margin: 0,
					background: value > 40 ? (value > 80 ? "#E7FAF0" : "#FFF6E6") : "#FFEEEC"
				},
				dataLabels: {
					name: {
						fontSize: '0',
					},
					value: {
						fontSize: '2em',
						color: value > 40 ? (value > 80 ? "#0CCE6B" : "#FFA400") : "#FF4E42"
					},
					total: {
						show: true,
						label: '',
						formatter: function (w) {
							return Math.round(value)
						}
					}
				}
			},
		},
		stroke: {
			lineCap: 'round'
		},
		labels: ['Punteggio Totale'],
		colors: value > 40 ? (value > 80 ? ["#0CCE6B"] : ["#FFA400"]) : ["#FF4E42"]
	};

	return (
		<div id="chart">
			<ReactApexChart options={options} series={series} type="radialBar" />
		</div>
	);
}

const SectionOverviewChart = ({ data }) => {
	const series = data.sections.map(s => s.weightedScore);
	const options = {
		chart: {
			width: "100%",
			type: 'polarArea'
		},
		labels:  data.sections.map(s => s.label),
		colors: data.sections.map(s => s.weightedScore > 40 ? (s.weightedScore > 80 ? ["#0CCE6B"] : ["#FFA400"]) : ["#FF4E42"]),
		fill: {
			opacity: 1
		},
		stroke: {
			width: 1,
			colors: undefined
		},
		yaxis: {
			show: false
		},
		legend: {
			show: true,
			position: 'bottom'
		},
		plotOptions: {
			polarArea: {
				rings: {
					strokeWidth: 0,
				}
			}
		},
		theme: {
			monochrome: {
				enabled: true,
				shadeTo: 'light',
				shadeIntensity: 0.6
			}
		}
	}

	return (
		<div id="chart">
			<ReactApexChart options={options} series={series} type="polarArea"  />
		</div>
	);
}

const SectionOverviewIstogram = ({ data }) => {
	const series = [{
		data: data.sections.map(s => s.weightedScore)
	}];
	const options = {
		chart: {
			width: "100%",
			type: 'bar',
			events: {
				click: function(chart, w, e) {
					// console.log(chart, w, e)
				}
			}
		},
		colors: data.sections.map(s => s.weightedScore > 40 ? (s.weightedScore > 80 ? "#0CCE6B" : "#FFA400") : "#FF4E42"),
		plotOptions: {
			bar: {
				borderRadius: 6,
				columnWidth: '45%',
				distributed: true,
			}
		},
		dataLabels: {
			enabled: false,
			total: {
				show: true,
				label: '',
				formatter: function (w) {
					return Math.round(w) + "%"
				}
			}
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return Math.round(val) + "%"
				}
			}
		},
		legend: {
			show: false
		},
		xaxis: {
			categories: data.sections.map(s => s.label),
			labels: {
				style: {
					colors: data.sections.map(s => s.weightedScore > 40 ? (s.weightedScore > 80 ? "#0CCE6B" : "#FFA400") : "#FF4E42"),
					fontSize: '12px'
				}
			}
		}
	}

	return (
		<div id="chart">
			<ReactApexChart options={options} series={series} type="bar" />
		</div>
	);
}

export default Report;
