import React, {Component} from "react";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip, Modal, ModalHeader, ModalBody, FormGroup, ListGroup, ListGroupItem, ModalFooter
} from "reactstrap";

import ReactTable from "react-table";

import Header from "components/Headers/Header.js";
import ApiService from "../services/classes/Api";
import DataTableComponent from "../components/Tables/DataTable";
import moment from 'moment';
import {Button, InputLabel, Switch, TextField, Tooltip} from "@material-ui/core";
import {faKeyboard} from "@fortawesome/free-solid-svg-icons/faKeyboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudDownloadAlt, faPlus} from "@fortawesome/free-solid-svg-icons";
import Col from "reactstrap/es/Col";
import Loading from "../components/General/Loading";
import RecordDataForm from "../components/General/RecordDataForm";
import {Slide, toast} from "react-toastify";
import Validator from "../helpers/Validations";

import survey from "../assets/survey.json";

const sortLeadsByDate = (a, b) => new Date(parseInt(b.createdAt)).getTime() - new Date(parseInt(a.createdAt)).getTime();

class Answers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      answers: [],
      page: 1,
      limit: 10,
      loading: false
    };
    this.export = this.export.bind(this);
    this.exportOpentext = this.exportOpentext.bind(this);
    this.exportFull = this.exportFull.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({loading: true});
    const { page, limit } = this.state;
    const sort = "{ \\\"createdAt\\\": 1 }";
    ApiService.graph(`
      {
        recordsFeed(type: "answer", page: ${page}, limit: ${limit}, sort: "${sort}" ) { records { _id state data createdAt updatedAt } pagination { total lastPage } }
        topology(name: "answer") { _id name plural_label label shape states }  
        topologies { _id name plural_label label shape states }  
      }
    `).then(response => {
      this.setState({loading: false});
      if(response.status === 200)
        this.setState({
          answers: response.data.data.recordsFeed.records,
          topology: response.data.data.topology,
          topologies: response.data.data.topologies,
          total: response.data.data.recordsFeed.pagination.total,
          lastPage: response.data.data.recordsFeed.pagination.lastPage
        });
      else
        alert("error");
    }).catch(e => alert("error"))
  }

  createPage(data) {
    ApiService.createRecord("answer", data, "new").then(response => {
      toast("Risposta aggiunta correttamente.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'success'
      });
      this.loadData();
    }).catch(e => {
      toast("Si è verificato un problema, riprova più tardi.", {
        transition: Slide,
        closeButton: true,
        autoClose: 2000,
        position: 'bottom-center',
        type: 'error'
      });
    });
  }

  editLead(oid) {
    window.location.href = "/admin/reports/" + oid
  }

  export() {
    this.setState({loading: true});
    ApiService.export("answer").then(response => response.blob()).then(blob => {

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export_${moment(new Date()).format("YYYY-MM-DD_HH-mm")}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      this.setState({loading: false});
    })
  }

  exportOpentext() {
    this.setState({loading: true});
    ApiService.exportOpentext().then(response => response.blob()).then(blob => {

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export_${moment(new Date()).format("YYYY-MM-DD_HH-mm")}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      this.setState({loading: false});
    })
  }

  exportFull() {
    this.setState({loading: true});
    ApiService.exportFull().then(response => response.blob()).then(blob => {

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `export_${moment(new Date()).format("YYYY-MM-DD_HH-mm")}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      this.setState({loading: false});
    })
  }

  setPage(page) {
    this.setState({ page }, () => this.loadData());
  }

  render() {

    if(!this.state.answers || !this.state.topology) {
      return <Loading />
    }

    const { answers, loading, topology, topologies, page, total, limit, lastPage } = this.state;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <DataTableComponent
                title={"Risposte al Sondaggio"}
                actions={
                  <>
                    {/*<AddLeadDialog confirmCallback={(data) => this.createPage(data)} topology={topology} topologies={topologies} button={<Button color={"success"} style={{background: "#2dce89", color: "#fff"}} variant={"contained"}startIcon={<FontAwesomeIcon icon={faPlus} size="1x" />} >Nuovo Termine</Button>} />
                    <span> </span>*/}
                    <Button onClick={this.exportOpentext} color={"info"} variant={"outlined"} startIcon={<FontAwesomeIcon icon={faCloudDownloadAlt} size="1x" />}>Esporta risposte aperte</Button>
                    <Button onClick={this.exportFull} color={"info"} variant={"outlined"} className={"ml-3"} startIcon={<FontAwesomeIcon icon={faCloudDownloadAlt} size="1x" />}>Esporta risposte non aperte</Button>
                  </>
                }
                data={answers}
                loading={loading}
                onClick={(r) => this.editLead(r)}
                page={page}
                total={total}
                lastPage={lastPage}
                setPage={(page) => this.setPage(page)}
                schema={[
                  {
                    name: "Nome",
                    selector: (r) => <QuestionAnswer answer={r.data.name} />
                  },
                  {
                    name: "Cognome",
                    selector: (r) => <QuestionAnswer answer={r.data.surname} />
                  },
                  {
                    name: "Email",
                    selector: (r) => <QuestionAnswer answer={r.data.email} />
                  },
                  {
                    name: "Questionario",
                    selector: (r) => r.data.survey === "strategic" ? "Strategico" : "Completo"
                  },
                  {
                    name: "Completezza",
                    selector: (r) => (
                      <Tooltip title={`${Object.keys(r.data).filter(k => /\d/.test(k)).length} domande su ${r.data.survey === "all" ? survey.questions.length : survey.questions.filter(q => q.survey === r.data.survey).length}`} placement="bottom">
                        <Progress value={Object.keys(r.data).filter(k => /\d/.test(k)).length} max={r.data.survey === "all" ? survey.questions.length : survey.questions.filter(q => q.survey === r.data.survey).length} />
                      </Tooltip>
                    )
                  },
                  {
                    name: "Data Creazione",
                    selector: (r) => moment(parseInt(r.createdAt)).format("DD/MM/YYYY, HH:mm:ss")
                  }
                ]}
              />
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const QuestionAnswer = (props) => {
  if(typeof props.answer?.map === "function") {
    return <>{props.answer?.map(o => `${o}, `)}</>
  } else {
    return <>{props.answer}</>
  }
}

class AddLeadDialog extends Component {

  state = {
    modal: false,
    data: {}
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.confirm = this.confirm.bind(this);
    this.isDataValid = this.isDataValid.bind(this);
  }

  toggle() {
    this.setState(previousState => ({modal: !previousState.modal}) )
  }

  isDataValid() {
    const state = "new";
    const { data } = this.state;
    const { topology } = this.props;
    const result = Validator.validate(data, topology, state);
    console.log(result);
    return result.length <= 0;
  }

  confirm() {
    const { data } = this.state;
    if(this.isDataValid()) {
      this.props.confirmCallback({...data});
      this.toggle();
    }
  }

  render() {

    const { name, label, plural_label } = this.props.topology;

    return (
      <React.Fragment>
				<span className="d-inline-block">
					<span onClick={this.toggle}> {this.props.button}</span>
						<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
      	    	<ModalHeader toggle={this.toggle}>Crea nuovo { label }</ModalHeader>
	    	    	<ModalBody>
								<Card body className="card-shadow-primary border mb-3">
                  <RecordDataForm state={"new"} updateRecordData={(data) => this.setState({data})} topology={this.props.topology} data={{}} topologies={this.props.topologies} />
		  	    	  </Card>
	    	    	</ModalBody>
      	    	<ModalFooter>
      	    	  <Button outline color="danger" className={"border-0 btn-transition"} onClick={this.toggle}> Annulla </Button>
      	    	  <Button color="success" onClick={this.confirm}> Salva </Button>
      	    	</ModalFooter>
      	   </Modal>
				</span>
      </React.Fragment>
    )
  }
};

const StateBadge = (props) => {
  const { state } = props;
  switch(state) {
    case "new":
      return <Badge style={{width: "100%"}} color="info"> Da Lavorare </Badge>;
      break;
    case "validated":
      return <Badge style={{width: "100%"}} color={"success"}> Validata </Badge>;
    case "invalid":
      return <Badge style={{width: "100%"}} color={"danger"}> Scartata </Badge>;
      break;
    default:
      return <Badge style={{width: "100%"}} color={"secondary"} className={"badge-dot mr-4"}><i className={"bg-secondary"} /> Indefinito </Badge>;
  }
};

export default Answers;
