import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardTitle
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import ApiService from "../services/classes/Api";
import ReactApexChart from "react-apexcharts";

class Index extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      stats: {},
      activeNav: 1,
      chartExample1Data: "data1"
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    ApiService.getGeneralReport().then(response => {
      console.log(response.data)
		  this.setState({
        stats: response.data.stats
      });
    }).catch(e => console.log(e));
  }

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
  };
  render() {

    const { stats } = this.state;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--5" fluid>

          <Row>
            <Col lg="6" xl="6" xs={12}>
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Compilazioni
                      </CardTitle>
                      <h2 style={{fontSize: "32px"}} className="font-weight-bold mb-0">
                       { stats?.total || 0 }
                      </h2>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" xl="6" xs={12}>
              <Card className="card-stats mb-4 mb-xl-0">
                <CardBody>
                  <Row>
                    <div className="col">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase text-muted mb-0"
                      >
                        Completamento medio
                      </CardTitle>
                      <h2 style={{fontSize: "32px"}} className="font-weight-bold mb-0">{ Math.round(stats?.completionMean || 0) }<small>%</small></h2>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className={"mt-5"}>
            <Col className="mb-5 mb-xl-0" xl="6" xs={12}>
              <Row>
                <Col xs={12}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Risposte Positive
                          </CardTitle>
                          <h2 style={{fontSize: "32px"}} className="font-weight-bold mb-0">{ Math.round(stats?.positiveAnswersMean || 0) }<small>%</small></h2>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col className={"mt-3"} xs={12}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Risposte Neutre
                          </CardTitle>
                          <h2 style={{fontSize: "32px"}} className="font-weight-bold mb-0">{ Math.round(stats?.neutralAnswersMean || 0) }<small>%</small></h2>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col className={"mt-3"} xs={12}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Risposte Negative
                          </CardTitle>
                          <h2 style={{fontSize: "32px"}} className="font-weight-bold mb-0">{ Math.round(stats?.negativeAnswersMean || 0) }<small>%</small></h2>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xl="6" xs={12}>
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Sezioni</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                    <CategoriesPerformanceChart data={stats?.sectionsPerformances || []} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const CategoriesPerformanceChart = ({ data }) => {
 /* const series = data ? data?.map(s => ({
    name: s.label,
    data: [s.positive, s.neutral, s.negative]
  })) : [];*/
  const series = [
    {
      name: "Positive",
      data: data.map(s => Math.round(s.positive))
    },
    {
      name: "Neutrali",
      data: data.map(s => Math.round(s.neutral))
    },
    {
      name: "Negative",
      data: data.map(s => Math.round(s.negative))
    },
  ]
  const options = {
    chart: {
      type: 'bar',
      width: "100%",
      stacked: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: data.map(d => d.label),
      labels: {
        formatter: function (val) {
          return Math.round(val) + "%"
        }
      }
    },
    yaxis: {
      title: {
        text: undefined
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%"
        }
      }
    },
    fill: {
      opacity: 1,
      colors: ["#0CCE6B", "#FFA400", "#FF4E42"]
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
      markers: {
        fillColors: ["#0CCE6B", "#FFA400", "#FF4E42"]
      }
    }
  }

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar"  />
    </div>
  );
}

export default Index;
